<template>
  <div class="Introduction" ref="Introduction" @scroll="handleScroll">
    <tab-view></tab-view>
    <div
      class="tab-content-fix"
      ref="tabFix"
      :style="{ top: elementTop + 'px', position: 'absolute' }"
    >
      <div
        class="item flex-vertical"
        :class="{ active: index === currentTabIndex }"
        v-for="(item, index) in tabList"
        :key="item.zh"
      >
        <span
          class="tabname flex-vertical cursor-pointer"
          @click="changeTab(index)"
          >{{ item.zh }}</span
        >
        <i class="line"></i>
      </div>
    </div>
    <!-- 业务介绍 -->
    <div class="introduction-top" ref="introductionTop">
      <div class="tab" ref="tabbox">
        <div class="background-div" ref="backgroundbox"></div>
      </div>
    </div>
    <!-- 服务对象 -->
    <div class="srvice-object" ref="srviceObject">
      <div class="Service-title flex-vertical">
        <div class="zh">
          {{
            tabList[0].zh == "服务对象"
              ? "服务对象:有资产的企业家"
              : tabList[0].zh
          }}
        </div>
        <div class="eh">{{ tabList[0].eh }}</div>
      </div>
      <img
        class="image"
        src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2002.png"
        alt=""
      />
      <!-- <div class="card">
        <div class="icon left">
          <img
            class="image"
            src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2008.png"
            alt=""
          />
        </div>
        <div class="right">
          <span
            >积极响应国家号召，助力中小微企业发展<br />
            帮助中小企业解决发展资金问题!</span
          >
        </div>
      </div> -->
    </div>
    <!-- 服务内容 -->
    <div class="Service-Content" ref="ServiceContent">
      <div class="Service-title flex-vertical">
        <div class="zh">{{ tabList[1].zh }}</div>
        <div class="eh">{{ tabList[1].eh }}</div>
      </div>
      <div class="content">
        <div
          class="item"
          :style="{ backgroundImage: 'url(' + item.url + ')' }"
          :key="item.title"
          v-for="item in contentData"
        >
          <div class="item-content flex-vertical cursor-pointer">
            <span class="title">{{ item.title }}</span>
            <span class="illustrate">{{ item.illustrate }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 业务流程 -->
    <div class="Operation-Flow" ref="OperationFlow">
      <div class="Service-title flex-vertical">
        <div class="zh">{{ tabList[2].zh }}</div>
        <div class="eh">{{ tabList[2].eh }}</div>
      </div>
      <div class="content">
        业务流程透明，高效，流程模块化专人专岗位，业务部负责业务承接，风控部负责为客户匹配最优的方案，商务部负责市场及大渠道开发，客户服务中心负责客户贷后一系列流程。多部门协助作，6—8人服务一位客户。
      </div>
      <div class="flow-box">
        <div class="item-box" v-for="item in flowData" :key="item.id">
          <img class="item-image" :src="item.url" alt="" />
          <div class="bumen">{{ item.bumen }}</div>
          <div class="item-msg">{{ item.msg }}</div>
        </div>
        <img
          class="flow-box-img"
          src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2007.png"
          alt=""
        />
      </div>
    </div>
    <!-- 业务优势 -->
    <div class="Business-Advantages" ref="BusinessAdvantages">
      <div class="Service-title flex-vertical">
        <div class="zh">{{ tabList[3].zh }}</div>
        <div class="eh">{{ tabList[3].eh }}</div>
      </div>
      <div class="content">
        <div
          class="item flex-vertical cursor-pointer"
          v-for="item in advantData"
          :key="item.style"
        >
          <span class="style">{{ item.style }}</span>
          <span class="msg">{{ item.msg }}</span>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <footer-box @handleMode="changeTab"></footer-box>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
import footerBox from "./../components/footer.vue";
export default {
  components: { tabView, footerBox },
  data() {
    return {
      currentTabIndex: 0,
      flowData: [
        {
          id: 1,
          bumen: "业务部",
          msg: "负责业务承接",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2009.png",
        },
        {
          id: 2,
          bumen: "风控部",
          msg: "负责为客户匹配最优的方案",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2010.png",
        },
        {
          id: 3,
          bumen: "商务部",
          msg: "负责市场及大渠道开发",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2011.png",
        },
        {
          id: 4,
          bumen: "客户服务中心",
          msg: "负责贷后一系列流程",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2012.png",
        },
      ],
      tabList: [
        { zh: "服务对象", eh: "Service Object" },
        { zh: "服务内容", eh: "Service Content" },
        { zh: "业务流程", eh: "Operation Flow" },
        { zh: "业务优势", eh: "Business Advantages" },
      ],
      contentData: [
        {
          title: "资金规划",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2003.png",
          illustrate: "帮助中小企业能快速获得企业发展资金",
        },
        {
          title: "资产规划",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2004.png",
          illustrate: "帮助中小企业主快速买资产或资产变现",
        },
        {
          title: "债务规划",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2005.png",
          illustrate: "帮助中小企业合理负债，搭建负债结构",
        },
        {
          title: "征信规划",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2006.png",
          illustrate: "帮助企业预防改善构建健康的信用体系",
        },
      ],
      advantData: [
        { style: "快", msg: "流程操作高效" },
        { style: "准", msg: "方案匹配精准" },
        { style: "稳", msg: "售后服务持续" },
        { style: "狠", msg: "团队狼性作战" },
      ],
      elementTop: 600,
    };
  },
  mounted() {
    let mode = this.$route.params && this.$route.params.mode;
    console.log("mode", this.$route.params, mode);
    this.$nextTick(() => {
      let introductionTop = this.$refs.introductionTop.offsetHeight;
      let height1 = this.$refs.backgroundbox.offsetHeight;
      let height2 = this.$refs.tabFix.offsetHeight;
      this.elementTop = introductionTop - height1 - height2;
      this.top = this.elementTop; // 暂存
      console.log("data", height1, height2, this.elementTop);
      if (mode) {
        // this.currentTabIndex = mode;
        this.changeTab(mode);
      }
    });
  },
  methods: {
    handleScroll(e) {
      // this.$nextTick(() => {
      // console.log(e.target.scrollTop);
      let scrollTop = e.target.scrollTop;
      let srviceObject = this.$refs.srviceObject.offsetHeight;
      let ServiceContent = this.$refs.ServiceContent.offsetHeight;
      let OperationFlow = this.$refs.OperationFlow.offsetHeight;
      let BusinessAdvantages = this.$refs.BusinessAdvantages.offsetHeight;
      let tabFix = Number(this.$refs.tabFix.offsetHeight);
      let introductionTop = this.$refs.introductionTop.offsetHeight;
      // let data = this.$refs.backgroundbox.getBoundingClientRect();
      let height = this.$refs.backgroundbox.offsetHeight;
      // console.log(
      //   "this.$refs.Introduction",
      //   scrollTop,
      //   introductionTop - height - tabFix
      // );

      if (scrollTop > introductionTop - height - tabFix) {
        this.elementTop = 0;
        this.$refs.tabFix.style.position = "fixed";
      } else {
        this.elementTop = this.top;
        this.$refs.tabFix.style.position = "absolute";
      }

      if (scrollTop < srviceObject + introductionTop - tabFix) {
        this.currentTabIndex = 0;
      } else if (
        scrollTop <
        ServiceContent + srviceObject + introductionTop - tabFix
      ) {
        this.currentTabIndex = 1;
      } else if (
        scrollTop <
        OperationFlow + ServiceContent + srviceObject + introductionTop - tabFix
      ) {
        this.currentTabIndex = 2;
      } else {
        this.currentTabIndex = 3;
      }
      // });
    },
    changeTab(index) {
      if (index == "top") {
        this.$nextTick(() => {
          this.currentTabIndex = 0;
          let box = this.$refs.Introduction;
          box.scrollTop = 0;
        });
        return;
      }
      if (this.currentTabIndex === index) {
        return;
      }
      console.log(
        "---------------------",
        index,
        this.currentTabIndex,
        this.$refs.Introduction.scrollTop
      );
      this.$nextTick(() => {
        let box = this.$refs.Introduction;
        let height1 = this.$refs.backgroundbox.offsetHeight;
        let srviceObject = this.$refs.srviceObject.offsetHeight;
        let ServiceContent = this.$refs.ServiceContent.offsetHeight;
        let OperationFlow = this.$refs.OperationFlow.offsetHeight;
        let BusinessAdvantages = this.$refs.BusinessAdvantages.offsetHeight;
        let tabFix = Number(this.$refs.tabFix.offsetHeight);
        let introductionTop = this.$refs.introductionTop.offsetHeight;
        if (index == 0) box.scrollTop = introductionTop - tabFix - height1;
        if (index == 1) box.scrollTop = introductionTop + srviceObject - tabFix;
        if (index == 2)
          box.scrollTop =
            introductionTop + srviceObject + ServiceContent - tabFix;
        if (index == 3)
          box.scrollTop =
            introductionTop +
            srviceObject +
            ServiceContent +
            OperationFlow -
            tabFix;
        setTimeout(() => {
          this.currentTabIndex = index;
        }, 50);
      });
    },
  },
};
</script>

<style lang='less' scoped>
.Introduction {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  // fix布局
  .tab-content-fix {
    width: 100%;
    left: 0;
    background: #ffffff;
    border-radius: 240px 0px 0px 0px;
    z-index: 999;

    padding: 0 240px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 180px;
    border-bottom: 1px solid #d6d9dd;
    .item {
      height: 100%;
      padding-bottom: 0px;
      .tabname {
        height: calc(100% - 8px);
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #888;
      }

      .line {
        width: 64px;
        height: 8px;
        background: #fff;
        opacity: 0.9;
        border-radius: 4px;
      }
      &.active {
        .tabname {
          color: #a12f32;
        }
        .line {
          background: #a12f32;
        }
      }
    }
  }

  .Service-title {
    height: 160px;
    margin-bottom: 48px;
    .zh {
      height: 48px;
      font-size: 50px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #111111;
      line-height: 20px;
    }
    .eh {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #444444;
      line-height: 20px;
      margin-top: 10px;
    }
  }
  .introduction-top {
    padding-bottom: 100px;
    height: 840px;
    width: 100%;
    position: relative;
    background-image: url("https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2001.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .tab {
      width: 100%;
      height: 240px;
      position: absolute;
      bottom: 0px;
      left: 0;
      // background: #ffffff;
      // border-radius: 240px 0px 0px 0px;
      //
      padding-bottom: 60px;
      .background-div {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        background: #ffffff;
      }
    }
  }
  //   服务对象
  .srvice-object {
    padding: 0 240px;
    position: relative;
    text-align: center;
    .image {
      width: 1100px;
      height: 660px;
    }
    .card {
      height: 200px;
      position: absolute;
      right: 240px;
      bottom: 260px;
      background: #a12f32;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 60px;
      .left {
        width: 120px;
        height: 120px;
        background: #a12f32;
        border: 2px solid #ffffff;
        border-radius: 60px;
        margin-right: 40px;
        .image {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 48px;
      }
    }
  }
  //   服务内容
  .Service-Content {
    margin-top: 130px;
    padding-bottom: 140px;
    .content {
      display: flex;
      justify-content: space-between;
      padding: 0 240px;
      .item {
        width: calc(25% - 10px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        .item-content {
          padding: 240px 64px;
          opacity: 0.5;
          background: #000000;
          .title {
            height: 34px;
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 48px;
            margin-bottom: 70px;
          }
          .illustrate {
            text-align: center;
            height: 65px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          &:hover {
            opacity: 1;
            background: #a12f32;
          }
        }
      }
    }
  }
  //   业务流程
  .Operation-Flow {
    width: 100%;
    position: relative;
    height: 1040px;
    background: #f7f7f7;
    padding: 60px 240px 140px;
    .content {
      width: 710px;
      // height: 91px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #111111;
      // line-height: 40px;
    }
    .flow-box {
      width: 100%;
      top: 413px;
      left: 0;
      position: absolute;
      .flow-box-img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 270px;
        z-index: 9;
      }
      .item-box {
        position: absolute;
        width: 300px;
        height: 400px;
        padding: 40px;
        padding-right: 0px;
        background: #fff;
        box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        overflow: hidden;
        .item-image {
          width: 56px;
          height: 56px;
        }
        .bumen {
          margin-top: 90px;
          margin-bottom: 30px;
          font-size: 36px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
        }
        .item-msg {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #111111;
        }
        &:nth-child(1) {
          left: 240px;
          top: 84px;
        }
        &:nth-child(2) {
          left: 620px;
          top: 24px;
        }
        &:nth-child(3) {
          left: 1000px;
          top: -36px;
        }
        &:nth-child(4) {
          right: 240px;
          top: -96px;
        }
      }
    }
  }
  //   业务优势
  .Business-Advantages {
    height: 730px;
    background: #fff;
    padding: 80px 240px 140px;
    .content {
      display: flex;
      justify-content: space-between;
      .item {
        width: calc(25% - 10px);
        padding: 80px 100px 100px;
        background: #eeeeee;
        opacity: 0.9;
        .style {
          margin-bottom: 40px;
          height: 46px;
          font-size: 50px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #a12f32;
          line-height: 20px;
        }
        .msg {
          text-align: center;
          height: 23px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
          // line-height: 20px;
        }
        &:hover {
          background-image: url("https://xjsd-web.oss-cn-shanghai.aliyuncs.com/business/2013.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          .style,
          .msg {
            color: #eee;
          }
        }
      }
    }
  }
}
</style>