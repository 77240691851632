<template>
  <div class="businessLicen">
    <img
      class="image"
      src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1000.png"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
.businessLicen {
  display: flex;
  justify-content: center;
  padding: 10px;
  .image {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 70px) {
    .image {
      width: calc(100% - 40px);
    }
  }

  @media screen and (min-width: 700px) and (max-width: 1200px) {
    .image {
      width: 90%;
    }
  }

  @media screen and (min-width: 1200px) {
    .image {
      width: 1200px;
    }
  }
}
</style>