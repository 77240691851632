<template>
  <div class="app">
    <keep-alive>
      <router-view v-if="$route.meta && $route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta || !$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  created() {
    let userInfo = JSON.parse(localStorage.getItem("smallIsBig-userInfo"));
    userInfo && userInfo.token && this.$store.commit("saveUserInfo", userInfo);
    console.log("userInfo====>", userInfo);
  },
};
</script>

<style></style>
