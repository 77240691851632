<template>
  <div class="recruitment" ref="recruitment">
    <tab-view></tab-view>
    <div class="recruitment-top">
      <div class="recruitment-title flex-vertical">
        <div class="zh">招贤纳士</div>
        <div class="eh">RECRUITMENT</div>
      </div>
      <div class="recruitment-type flex-vertical" ref="recruitmentTitle">
        <div class="style-zh">社会招聘</div>
        <div class="style-eh">Social Recruitment</div>
        <div class="input-box">
          <div class="left">职位</div>
          <input
            type="text"
            placeholder="请输入相关职位"
            class="inputcontent"
          />
          <div class="button cursor-pointer" flex-center>点击搜索</div>
        </div>
      </div>
    </div>
    <!-- 详细内容 -->
    <div class="position-contion">
      <div class="item title">
        <span class="name">职位名称</span>
        <span class="salary">薪资待遇</span>
        <span class="address">工作地点</span>
        <span class="years">工作年限</span>
        <span class="education">学历</span>
        <span class="apply flex-vertical">申请</span>
      </div>
      <div class="item-box" :key="item.id" v-for="(item, index) in dataList">
        <div class="item">
          <span class="name">{{ item.name || "--" }}</span>
          <span class="salary">{{ item.salary || "--" }}</span>
          <span class="address">{{ item.address || "--" }}</span>
          <span class="years">{{ item.years || "--" }}</span>
          <span class="education">{{ item.education || "--" }}</span>
          <span class="apply flex-vertical"
            ><i
              @click="handleClick(item.id)"
              class="button cursor-pointer"
              :class="{ 'active-but': activeId === item.id }"
              >{{ item.apply }}</i
            ></span
          >
        </div>
        <div class="item-content" v-if="activeId === item.id">
          <template v-for="(objvalue, objkey) in item.details" v-key="objkey">
            <span v-show="objkey != 'notes'">{{ objkey }}:</span>
            <template v-if="Array.isArray(objvalue)">
              <div
                class="item-res"
                style="padding-left: 8ch"
                :key="detailItem"
                v-for="detailItem in objvalue"
              >
                <span>{{ detailItem }}</span>
              </div>
            </template>
            <template v-else>
              <span>{{ objvalue }}</span
              ><br />
            </template>
          </template>
        </div>
      </div>
    </div>
    <el-pagination
      class="page-select"
      hide-on-single-page
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="pageData.currentPage"
      :total="this.dataList.length"
    >
    </el-pagination>
    <footer-box @handleMode="changeTab"></footer-box>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
import footerBox from "./../components/footer.vue";
export default {
  components: { tabView, footerBox },
  data() {
    return {
      pageData: {
        total: 56,
        currentPage: 2,
      },
      activeId: "",
      dataList: [
        {
          id: 1,
          name: "公司金融顾问",
          salary: "（10K-100K ）",
          address: "上海闵行区闵虹路166弄中庚环球创意中心T2座2715室",
          years: "",
          education: "大专",
          apply: "毛遂自荐",
          details: {
            岗位名称: "公司金融顾问",
            招聘人数: "30人",
            薪资范围: "（10K-100K ）",
            岗位描述: [
              "1.研究分析宏观经济形势、产业政策及客户发展战略，指导客户制定中长期金融发展规划；",
              "2.帮助客户拓宽投融资渠道，提高金融需求匹配效率；",
              "3.分析客户资产、现金流特征，指导客户制定、调整现金管理方案；",
              "4.帮助客户优化和管理资本结构；",
              "5.指导客户识别、评估、分析金融风险，提供金融风险防控和处置建议；",
              "6.提供金融信息分析、咨询服务，指导客户与银行等金融机构接洽，帮助解决信息不对称问题；",
              "7.帮助客户完善投融资决策体系，提供相关政策戒解读政策",
              "（银行贷款产品销售）",
            ],
            岗位要求: [
              "1、大专学历以上、35岁以内，应届毕业生亦可，特别优秀者可放宽相关条件",
              "2、1-2年在上海销售的工作经验，有一定人脉关系者优先考虑",
              "3、三观正，人品好，性格开朗、能吃苦耐劳",
              "4、学习能力强，适应能力强，有自信拿高薪",
            ],
            上班时间: "09:00-18:00   双休",
            上班地点: "上海闵行区闵虹路166弄中庚环球创意中心T2座2715室",
            "福 利": [
              "学历补贴、证书补贴、工龄补贴、餐费补贴、交通补贴、赡养老人补贴、五险一金、节日福利、假期福利、其他福利",
              "1、现金福利：开工利是、慰问费",
              "2、五险一金：养老保险、医疗保险、生育保险、失业保险、工伤保险、住房公积金",
              "3、节日福利：春节、妇女节、端午节、中秋节、国庆节",
              "4、假期福利：国家规定假日、法定节假日、年休假、产假、陪产假、哺乳假",
              "5、其他福利：节日福利、生日福利、员工培训、团建旅游",
            ],
          },
        },
        {
          id: 2,
          name: "团队经理",
          salary: "薪资面谈",
          address: "上海闵行区闵虹路166弄中庚环球创意中心T2/2715室",
          years: "",
          education: "大专",
          apply: "毛遂自荐",
          details: {
            岗位名称: "团队经理",
            招聘人数: "10名",
            岗位要求: [
              "大专以上学历，38岁以内，优秀者可放宽条件",
              "两年以上团队管理经验，带过不低于10人的销售团队",
              "具备谈判、营销、应变、逻辑能力，能吃苦耐劳",
              "有讲师或者做过银行零售、对公经验或有会销组织经验者优先",
            ],
            薪资待遇: "底薪+绩效+团队提成+奖金+分红+其他福利+股权激励",
            上班时间: "09:00-18:00双休",
            上班地址: "上海闵行区闵虹路166弄中庚环球创意中心T2/2715室",
          },
        },
        {
          id: 3,
          name: "业务经理",
          salary: "薪资面谈",
          address: "上海闵行区闵虹路166弄中庚环球创意中心T2/2715室",
          years: "",
          education: "大专",
          apply: "毛遂自荐",
          details: {
            岗位名称: "业务经理",
            招聘人数: "60名",
            岗位要求: [
              "大专以上学历，38岁以内，优秀者可放宽条件",
              "1-2年上海销售工作经验，有一定人脉关系者优先考虑",
              "有会销、保险、房产、银行零售或者对公等经验优先考虑",
              "三观正，人品好，能吃苦耐劳，有利他思维，奉献精神",
              "学习能力强，适应能力强，有自信拿高薪",
            ],
            薪资待遇: "底薪+绩效+提成（最高50%）+奖金+其他福利",
            上班时间: "09:00-18:00双休",
            上班地址: "上海闵行区闵虹路166弄中庚环球创意中心T2/2715室",
            notes: "(金融规划服务，非电销!)",
          },
        },
        {
          id: 4,
          name: "风控经理",
          salary: "薪资面谈",
          address: "上海闵行区闵虹路166弄中庚环球创意中心T2/2715室",
          years: "",
          education: "大专",
          apply: "毛遂自荐",
          details: {
            岗位名称: "风控经理",
            招聘人数: "10名",
            岗位要求: [
              "大专及以上学历，40岁以内，一年以上团队经验",
              "熟悉银行贷款产品，有银行对公、零售工作经验 者优先考虑",
              "逻辑和应变能力强、有营销能力，吃苦耐劳，有奉献 和创新精神",
              "效率高，执行力强，当日客户当日毕",
            ],
            薪资待遇: "底薪+提成+绩效+奖金+分红+股权激励",
            上班时间: "09:00-18:00双休",
            上班地址: "上海闵行区闵虹路166弄中庚环球创意中心T2/2715室",
          },
        },
      ],
    };
  },
  created() {
    let mode = this.$route.params && this.$route.params.mode;
    if (mode) {
      this.changeTab(mode);
    }
  },
  methods: {
    changeTab(val) {
      this.$nextTick(() => {
        let recruitment = this.$refs.recruitment;
        let top = this.$refs.recruitmentTitle.offsetTop;
        recruitment.scrollTop = val == "top" ? 0 : top;
      });
    },
    handleClick(id) {
      this.activeId = this.activeId === id ? "" : id;
    },
    handleCurrentChange(data) {
      console.log("handleCurrentChange", data);
      this.pageData.currentPage = data;
    },
  },
};
</script>

<style lang='less' scoped>
.recruitment {
  position: relative;
  opacity: 0.9;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  .recruitment-top {
    overflow: hidden;
    height: 1000px;
    width: 100%;
    position: relative;
    background-image: url("https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3001.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .recruitment-title {
      margin-top: 340px;
      .zh {
        font-size: 56px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        line-height: 20px;
        margin-bottom: 20px;
      }
      .eh {
        font-size: 30px;
        font-family: Humanst521 Lt BT;
        font-weight: 300;
        color: #ffffff;
        line-height: 80px;
      }
    }
    .recruitment-type {
      position: absolute;
      height: 400px;
      width: 100%;
      bottom: 0;
      padding-top: 110px;
      background: #ffffff;
      border-radius: 240px 0px 0px 0px;
      justify-content: start;

      .style-zh {
        height: 48px;
        font-size: 50px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 20px;
        margin-bottom: 20px;
      }
      .style-eh {
        height: 21px;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 20px;
      }
      .input-box {
        width: 800px;
        height: 72px;
        line-height: 70px;
        position: absolute;
        bottom: 0;
        background: #ffffff;
        border: 3px solid #a12f32;
        display: flex;
        padding: 12px 0;
        .left {
          width: 120px;
          border-right: 1px solid #a12f32;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
          line-height: 42px;
          text-align: center;
        }
        .inputcontent {
          border: none;
          flex: 1;
          padding: 0 30px;
          outline: none;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #888888;
          &::-webkit-input-placeholder {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #888888;
          }
        }
        .button {
          width: 140px;
          height: 72px;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 18px;
          text-align: center;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 72px;
          background-color: #a12f32;
        }
      }
    }
  }
  //   详细内容
  .position-contion {
    padding: 80px 240px 0;
    .item {
      display: flex;
      height: 64px;
      line-height: 64px;
      background: #eee;
      margin-bottom: 16px;
      padding: 0px 50px;
      opacity: 0.8;

      > span {
        flex: 1;
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #111111;
        overflow: hidden; /* 控制超出部分的隐藏效果 */
        white-space: nowrap; /* 不换行 */
        text-overflow: ellipsis;
      }
      .button {
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        font-style: normal;
        width: 120px;
        background-color: #aaa;
        border-radius: 8px;
        &.active-but {
          background-color: #a12f32;
        }
      }
      &.title {
        background: #cccccc;
        opacity: 0.9;
        > span {
          color: #111111;
        }
      }
    }
    .item-content {
      // height: 437px;
      padding: 0 90px 30px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #888888;
      // line-height: 28px;
      .requirements {
        margin-top: 20px;
      }
    }
  }

  .page-select {
    height: 60px;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    margin-bottom: 100px;
    margin-top: calc(100px - 16px);
    /deep/.el-pager {
      display: flex;
    }
    /deep/.btn-prev,
    /deep/.number,
    /deep/.btn-next {
      width: 60px !important;
      height: 60px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px !important;
      font-family: PingFang SC;
      font-weight: bold;
      color: #111111;
      .el-icon {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
      }
    }
  }
}
</style>