import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Introduction from '../views/Introduction.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import recruitment from '../views/recruitment.vue'
import ContactUs from '../views/ContactUs.vue'
import Login from '../views/Login.vue'
import BusinessLicen from '../views/businessLicen.vue'
import HomeBusDetail from '../views/homeBusDetail.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/login', name: 'login', component: Login },
    { path: '/home', name: 'home', component: Home, meta: { keepAlive: false } },
    { path: '/introduction', name: 'introduction', component: Introduction },
    { path: '/news', name: 'news', component: News },
    { path: '/newsDetail', name: 'newsDetail', component: NewsDetail },
    { path: '/recruitment', name: 'recruitment', component: recruitment },
    { path: '/contactUs', name: 'contactUs', component: ContactUs },
    { path: '/businessLicen', name: 'businessLicen', component: BusinessLicen },
    { path: '/homeBusDetail', name: 'homeBusDetail', component: HomeBusDetail },
  ]
})
// 菜单栏重复点击解决方式
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
