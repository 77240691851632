
import { setToken } from '@/utils/auth'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        userInfo: {
            mobile: '',
            password: '',
            token: '',
        }
    },
    mutations: {
        saveUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
    },
    actions: {

    }
})
export default store