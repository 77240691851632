<template>
  <div class="ContactUs" ref="ContactUs">
    <tab-view></tab-view>
    <img
      class="background"
      src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3001.png"
    />
    <div class="ContactUs-top flex-vertical">
      <div class="zh">联系我们</div>
      <div class="eh">Contact Us</div>
    </div>
    <div class="ContactUs-content" ref="ConUsContent">
      <div class="title flex-vertical">联系我们</div>
      <div class="content flex-level">
        <div class="content-left">
          <img
            class="icon"
            src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3002.png"
            alt=""
          />
          <div class="title">公司地址</div>
          <div class="adress-detail">
            上海市闵行区闵虹路166弄中庚创意中心T2,2715室
          </div>
          <img
            class="adress-img"
            src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3006.png"
            alt=""
          />
        </div>
        <div class="content-right">
          <div
            class="item flex-level"
            :key="item.type"
            v-for="item in contentType"
          >
            <img class="left" :src="item.icon" alt="" />
            <div class="center">
              <span class="type">{{ item.type }}</span>
              <span class="phone" v-if="item.phone">{{ item.phone }}</span>
              <span class="msg" v-if="item.msg">{{ item.msg }}</span>
            </div>
            <img class="code right" :src="item.code" alt="" v-if="item.code" />
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer-box @handleMode="changeTab"></footer-box>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
import footerBox from "./../components/footer.vue";
export default {
  components: { tabView, footerBox },
  data() {
    return {
      contentType: [
        {
          icon: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3005.png",
          type: "固定电话",
          phone: "4008556676",
        },
        {
          icon: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3004.png",
          type: "邮箱",
          phone: "987319506@qq.com",
        },
        {
          icon: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3003.png",
          type: "公众号留言",
          msg: "关注公众号后即可在平台留下您的问题和需求",
          code: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/contact/3007.png",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let mode = this.$route.params && this.$route.params.mode;
      // this.$refs.ContactUs.scrollTop = 0;
      // if (mode) {
      this.changeTab(mode ? mode : "top");
    });
  },
  methods: {
    changeTab(val) {
      this.$nextTick(() => {
        console.log("val0000", val);
        let ContactUs = this.$refs.ContactUs;
        let top = this.$refs.ConUsContent.offsetTop;
        ContactUs.scrollTop = val == "top" ? 0 : top;
      });
    },
  },
};
</script>

<style lang='less' scoped>
.ContactUs {
  position: relative;
  overflow: hidden;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .background {
    position: absolute;
    z-index: -1;
    height: 1000px;
    width: 100%;
  }
  .ContactUs-top {
    margin-top: 220px;
    height: 420px;
    .zh {
      font-size: 56px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      line-height: 20px;
      margin-bottom: 20px;
    }
    .eh {
      font-size: 30px;
      font-family: Humanst521 Lt BT;
      font-weight: 300;
      color: #ffffff;
      line-height: 80px;
    }
  }
  .ContactUs-content {
    background: #ffffff;
    border-radius: 240px 0px 0px 0px;
    margin-bottom: 150px;
    .title {
      height: 267px;
      font-size: 50px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #111111;
    }
    .content {
      height: 700px;
      padding: 0 240px;
      .content-left {
        padding: 32px;
        width: 800px;
        border: 1px solid #cccccc;
        height: 100%;
        .icon {
          width: 35px;
          height: 39px;
        }
        .title {
          margin-top: 36px;
          margin-bottom: 19px;
          height: 28px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
        }
        .adress-detail {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #111111;
          margin-bottom: 25px;
        }
        .adress-img {
          width: 100%;
          height: 460px;
        }
      }
      .content-right {
        width: calc(100% - 830px);
        height: 100%;
        .item {
          width: 100%;
          height: calc(33.33% - 21px);
          padding: 0 40px;
          margin-bottom: 32px;
          border: 1px solid #cccccc;
          &:last-child {
            margin-bottom: 0;
          }
          .left {
            width: 80px;
            height: 80px;
            margin-right: 30px;
          }
          .center {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 60px;
            padding-right: 20px;
            .type {
              height: 28px;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #111111;
              margin-bottom: 25px;
            }
            .phone {
              height: 18px;
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #111111;
            }
            .msg {
              height: 54px;
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #888888;
              line-height: 32px;
            }
          }
          .code {
            width: 148px;
            height: 148px;
          }
        }
      }
    }
  }
}
</style>