<template>
  <div id="tab-view">
    <div class="top-title flex-level">
      <div class="left">上海小就是大企业发展集团</div>
      <div class="right">
        <span
          :class="{ activeTab: currentTab === item.tab }"
          class="tab-item cursor-pointer"
          @click="swithTab(item)"
          v-for="item in tabList"
          :key="item.tab"
        >
          {{ item.tab }}
        </span>
        <span class="tab-item last-item"
          ><img
            class="icon-img"
            src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1001.png"
          />4008556676</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTab: "",
      tabList: [
        { tab: "首页", path: "home" },
        { tab: "业务介绍", path: "introduction" },
        { tab: "新闻资讯", path: "news" },
        { tab: "招贤纳士", path: "recruitment" },
        { tab: "联系我们", path: "contactUs" },
      ],
    };
  },
  created() {
    let path = this.$route.path && this.$route.path.replace("/", "");
    // if (path === "newsDetail") {
    //   this.currentTab = "新闻资讯";
    // } else {
    let data = this.tabList.filter((item) => item.path === path);
    this.currentTab = (data && data[0] && data[0].tab) || "";
    // }
    console.log(
      "this.currentTab---->",
      this.currentTab,
      this.$route.path,
      path
    );
  },
  methods: {
    swithTab(data) {
      if (this.currentTab === data.tab) {
        return;
      }
      this.currentTab = data.tab;
      this.$router.push({ name: data.path });
    },
  },
};
</script>

<style lang="less" scoped>
#tab-view {
  width: 100%;
  height: 120px;
  line-height: 120px;
  position: absolute;
  top: 60px;
  z-index: 99;
  .top-title {
    height: 100%;
    margin: 0px 160px 0;
    background-color: #1b4d92;
    border-radius: 60px;
    padding: 0 65px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    justify-content: space-between;
    .left {
      height: 100%;
      font-size: 26px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
    }
    .right {
      .activeTab {
        border-bottom: 1px solid #fff;
      }
      .tab-item {
        margin-left: 48px;
        padding-bottom: 20px;
        padding-left: 3px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
      }
      .last-item {
        overflow: hidden;
        position: relative;
        padding-left: 48px;
        .icon-img {
          position: absolute;
          width: 28px;
          height: 28px;
          left: 0;
          top: 0px;
        }
      }
    }
  }
}
</style>