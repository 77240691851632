<template>
  <div class="homeBusDetail">
    <tab-view></tab-view>
    <div class="homeBusDetail-top">
      {{ businessSectorList[sectorActive].title }}
    </div>
    <div class="homeBusDetail-img">
      <img
        v-for="url in businessSectorList[sectorActive].url"
        class="img"
        :src="url"
        :key="url"
      />
    </div>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
export default {
  components: { tabView },
  data() {
    return {
      sectorActive: 0,
      businessSectorList: [
        {
          title: "一起益企新媒体",
          url: [
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_1.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_2.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_3.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_4.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_5.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_6.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_7.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/1_8.png",
          ],
        },
        {
          title: "小就是大商学院",
          url: [
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_1.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_2.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_3.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_4.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_5.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_6.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_7.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_8.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_9.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_10.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_11.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_12.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_13.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_14.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/2_15.png",
          ],
        },
        {
          title: "小就是大名酒仓",
          url: [
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/3_1.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/3_2.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/3_3.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/3_4.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/3_5.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/3_6.png",
          ],
        },
        {
          title: "小火俱乐部",
          url: [
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_1.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_2.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_3.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_4.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_5.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_6.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_7.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_8.png",
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/sector/4_9.png",
          ],
        },
      ],
    };
  },
  created() {
    this.sectorActive =
      (this.$route.params && this.$route.params.sectorActive) || 0;
  },
};
</script>

<style lang='less' scoped>
.homeBusDetail {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 200px;
  .homeBusDetail-top {
    height: 120px;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 50px;
    color: #111111;
    line-height: 100px;
    text-align: center;
  }
  .homeBusDetail-img {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
    .img {
      width: calc(33.33% - 20px);
      margin-right: 30px;
      height: 300px;
      margin-bottom: 30px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>