<template>
  <div class="login">
    <div class="login-content flex-vertical">
      <form>
        <el-input
          class="input-box"
          placeholder="请输入用户名"
          v-model.trim="username"
          type="test"
        ></el-input>
        <el-input
          autocomplete="off"
          class="input-box"
          v-model.trim="password"
          type="password"
          placeholder="请输入密码"
          show-password
        ></el-input>
      </form>

      <div class="login-button">
        <el-button class="login-box" type="primary" @click="login"
          >登录</el-button
        >
        <el-button class="login-box" type="danger" @click="goback"
          >首页</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {
    let userInfo = this.$store.state.userInfo;
    console.log("userInfo11---->", userInfo);
    this.username = userInfo.mobile || "";
    this.password = userInfo.password || "";
  },
  methods: {
    goback() {
      // if (this.$route.params.back) {
      //   this.$router.back();
      // } else {
      //   this.$router.push("/home");
      // }
      this.$router.push("/home");
    },
    async login() {
      if (!this.username || !this.password) {
        this.$message({
          message: "请输入用户名和密码",
          type: "warning",
        });
        return;
      }
      const formData = new FormData();
      formData.append("mobile", this.username);
      formData.append("password", this.password);
      let res = await this.$axios.post("/admin/user/login", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      if (res.data && res.data.code == 0 && res.data.data) {
        console.log(res.data.data.token);
        let token = res.data.data.token;
        let userInfo = {
          mobile: this.username,
          password: this.password,
          token,
        };
        this.$store.commit("saveUserInfo", userInfo);
        localStorage.setItem("smallIsBig-userInfo", JSON.stringify(userInfo));

        this.$message.success("登录成功");
        //  根据参数back,login判断是否原路返回,否则转到user
        this.$router.push("/news");

        // if (this.$route.params.back) {
        //   this.$router.back();
        // } else {
        //   this.$router.push("/home");
        // }
      } else {
        // alert(res.data.message);
        this.$message.error(res.data.message || "登录失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  .login-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .input-box {
      width: 600px;
      display: block;
      margin-bottom: 30px;
      /deep/.el-input__inner {
        height: 100% !important;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .input-box {
      font-size: 30px;
      height: 100px;
    }
    .login-box {
      font-size: 30px;
      width: 200px;
      height: 100px;
    }
  }

  @media screen and (min-width: 700px) and (max-width: 1200px) {
    .input-box {
      font-size: 30px;
      height: 80px;
    }
    .login-box {
      font-size: 30px;
      width: 150px;
      height: 80px;
    }
  }

  @media screen and (min-width: 1200px) {
    .input-box {
      font-size: 20px;
      height: 60px;
    }
    .login-box {
      font-size: 20px;
      width: 100px;
      height: 50px;
    }
  }
}
</style>
