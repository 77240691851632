<template>
  <div class="News" ref="news">
    <tab-view></tab-view>
    <div class="News-top" ref="newsTop">
      <el-button
        v-if="token"
        icon="el-icon-plus"
        circle
        @click="handleAddNew"
        class="add-button"
        type="primary"
      ></el-button>
      <div class="tab" ref="newsTab">
        <div class="tab-content">
          <div
            class="item flex-vertical"
            :class="{ active: index === currentTabIndex }"
            v-for="(item, index) in tabList"
            :key="item.zh"
          >
            <span
              class="tabname cursor-pointer flex-vertical"
              @click="handleClice(index)"
              >{{ item.zh }}</span
            >
            <i class="line"></i>
          </div>
        </div>
        <div class="tab-select flex-vertical">
          {{ tabList[currentTabIndex].zh }}
        </div>
      </div>
    </div>
    <!-- 新闻内容 -->
    <div class="news-content" v-loading="loading">
      <template v-if="newsData.length">
        <div class="item" :key="item.id" v-for="item in newsData">
          <img class="image" :src="item.cover" />
          <div class="detail">
            <span class="time">{{ handleTime(item.time) }}</span>
            <span class="content">{{ item.title }}</span>
            <div class="more cursor-pointer" @click="goDetail(item)">
              了解更多+
            </div>
            <i
              v-if="token"
              class="edit cursor-pointer el-icon-edit"
              @click="handleEditBox(item)"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <img src="./../assets/image/nodata.jpeg" class="nodeta" />
      </template>
    </div>

    <el-pagination
      class="page-select"
      hide-on-single-page
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="pageData.currentPage"
      :total="pageData.total"
      :page-size="9"
    >
    </el-pagination>
    <!-- 底部 -->
    <footer-box @handleMode="changeTab"></footer-box>
    <edit-news
      v-model="isShow"
      :formData="formData"
      @handleList="getData"
      :tabList="tabList"
      :token="token"
    ></edit-news>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
import footerBox from "./../components/footer.vue";
import EditNews from "./editNews.vue";
export default {
  components: { tabView, footerBox, EditNews },
  data() {
    return {
      isShow: false,
      loading: true,
      currentTabIndex: 0,
      tabList: [
        { zh: "小就是大企服", type: 1 },
        { zh: "一起益企专访", type: 2 },
        { zh: "小就是大公益", type: 3 },
        { zh: "团队日常风采", type: 4 },
      ],
      pageData: {
        total: 0,
        currentPage: 1,
      },
      newsData: [],
      token: "",
      formData: {},
    };
  },
  created() {
    let mode = this.$route.params && this.$route.params.mode;
    this.currentTabIndex = mode || 0;
    this.getData();
    this.token = this.$store.state.userInfo && this.$store.state.userInfo.token;
    console.log("this.token--->>", this.token);
  },

  methods: {
    changeTab(vMode) {
      this.currentTabIndex = vMode == "top" ? 0 : vMode;
      this.getData();
      this.$nextTick(() => {
        let top = this.$refs.newsTab.offsetTop;
        this.$refs.news.scrollTop = vMode == "top" ? 0 : top;
      });
    },
    handleAddNew() {
      if (this.token) {
        this.formData = {
          id: "",
          type: this.tabList[this.currentTabIndex].type,
        };
        this.isShow = true;
      }
    },
    // 编辑
    handleEditBox(data) {
      this.formData = Object.assign({}, data);
      this.formData.time =
        (this.formData.time && this.formData.time * 1000) || "";
      this.formData.type = this.tabList[this.currentTabIndex].type;
      this.isShow = true;
    },
    handleClice(index) {
      if (this.currentTabIndex === index) return;
      this.currentTabIndex = index;
      this.pageData.currentPage = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      let page = this.pageData.currentPage;
      let page_size = 9;
      let type = this.tabList[this.currentTabIndex].type;
      const formData = new FormData();
      formData.append("page", page);
      formData.append("page_size", page_size);
      formData.append("type", type);
      this.$axios
        .post("/xd/article/list", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.data && res.data.code === 0 && res.data.data) {
            this.pageData.total = res.data.data.count || 0;
            this.newsData = [];
            (res.data.data.list || []).forEach((item) => {
              this.newsData.push(Object.assign({}, item, { isEdit: false }));
            });
          } else {
            this.pageData.total = 0;
            this.newsData = [];
          }
        });
    },
    handleTime(time) {
      if (!time) return "--";

      let date = new Date(time * 1000);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份从0开始，需要+1
      const day = date.getDate();
      return `${year}-${month}-${day}`;
    },
    handleCurrentChange(data) {
      console.log("handleCurrentChange", data);
      this.pageData.currentPage = data;
      this.getData();
    },
    goDetail(data) {
      if (!data || !data.url) {
        return;
      }
      window.open(data.url);
    },
  },
};
</script>

<style lang="less" scoped>
.News {
  position: relative;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  .News-top {
    padding-bottom: 100px;
    height: 1000px;
    width: 100%;
    position: relative;
    background-image: url("https://xjsd-web.oss-cn-shanghai.aliyuncs.com/news/5001.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .add-button {
      position: absolute;
      bottom: 410px;
      right: 20px;
      width: 70px;
      height: 70px;
      font-size: 25px;
    }
    .tab {
      width: 100%;
      height: 400px;
      position: absolute;
      bottom: 0px;
      left: 0;
      overflow: hidden;
      background: #ffffff;
      border-radius: 240px 0px 0px 0px;
      .tab-content {
        padding: 0 240px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 180px;
        border-bottom: 1px solid #d6d9dd;

        .item {
          height: 100%;
          padding-bottom: 0px;
          .tabname {
            height: calc(100% - 8px);
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #888;
          }

          .line {
            width: 64px;
            height: 8px;
            background: #fff;
            opacity: 0.9;
            border-radius: 4px;
          }
          &.active {
            .tabname {
              color: #a12f32;
            }
            .line {
              background: #a12f32;
            }
          }
        }
      }
      .tab-select {
        height: 220px;
        font-size: 50px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 20px;
      }
    }
  }
  // 新闻内容
  .news-content {
    padding: 0 240px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    .nodeta {
      display: block;
      width: 100%;
      height: auto;
    }
    .item {
      width: calc(33.33% - 20px);
      height: 520px;
      margin-right: 30px;
      border-radius: 16px 16px 0px 0px;
      overflow: hidden;
      background-color: #eee;
      margin-bottom: 40px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .image {
        width: 100%;
        height: 300px;
        object-fit: fill;
      }
      .detail {
        height: calc(100% - 300px);
        padding: 25px;
        display: flex;
        flex-direction: column;
        position: relative;
        .time {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #888888;
          line-height: 20px;
          margin-bottom: 25px;
        }
        .content {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #001111;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .more,
        .edit {
          position: absolute;
          bottom: 35px;
          left: 25px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #1b4d92;
        }
        .edit {
          left: auto;
          right: 25px;
        }
      }
    }
  }
  .page-select {
    height: 60px;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    margin-bottom: 100px;
    /deep/.el-pager {
      display: flex;
    }
    /deep/.btn-prev,
    /deep/.number,
    /deep/.more.el-icon-more,
    /deep/.btn-next {
      width: 60px !important;
      height: 60px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #111111;
      .el-icon {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
      }
    }
  }
}
</style>;
