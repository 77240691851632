<template>
  <!-- 首页底部 -->
  <div class="footer">
    <div class="company-name">上海小就是大企业发展集团</div>
    <i class="line"></i>
    <div class="detail flex-level">
      <div class="left">
        <div class="item" v-for="item in serveData" :key="item.tag">
          <span @click="handleClick(item.path)" class="tag cursor-pointer">{{
            item.tag
          }}</span>
          <div
            class="tag-item cursor-pointer"
            v-for="text in item.styleList"
            @click="jump(item.path, text.mode)"
            :key="text.title"
          >
            {{ text.title }}
          </div>
        </div>
      </div>
      <div class="right flex-center">
        <div class="phone-detail">
          <div class="phone">4008556676</div>
          <div class="nodes">周一至周五（9:00-18:00）</div>
        </div>
        <img
          class="image"
          src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1032.png"
        />
      </div>
    </div>
    <i class="line"></i>
    <div class="message">
      <span class="message-text"
        >备案号 921788237787 版权所有© 小就是大企业发展集团有限公司</span
      >
      <img
        @click="$router.push('businessLicen')"
        class="message-image"
        src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/999.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serveData: [
        {
          tag: "首页",
          path: "home",
          styleList: [
            { title: "集团简介", mode: "homeBrief" },
            { title: "发展历程", mode: "homeHistory" },
            { title: "业务板块", mode: "homeSector" },
            { title: "团队风采", mode: "homeStyle" },
            { title: "发展愿景", mode: "homeVision" },
          ],
        },
        {
          tag: "业务介绍",
          path: "introduction",
          styleList: [
            { title: "服务对象", mode: 0 },
            { title: "服务内容", mode: 1 },
            { title: "业务流程", mode: 2 },
            { title: "业务优势", mode: 3 },
          ],
        },
        {
          tag: "新闻资讯",
          path: "news",
          styleList: [
            { title: "小就是大企服", mode: 0 },
            { title: "一起益企专访", mode: 1 },
            { title: "小就是大公益", mode: 2 },
            { title: "团队日常风采", mode: 3 },
          ],
        },
        {
          tag: "招贤纳士",
          path: "recruitment",
          styleList: [
            { title: "岗位职责", mode: 1 },
            { title: "招聘要求", mode: 1 },
            { title: "毛遂自荐", mode: 1 },
          ],
        },
        {
          tag: "联系我们",
          path: "contactUs",
          styleList: [
            { title: "公司地址", mode: 1 },
            { title: "固定电话", mode: 1 },
            { title: "企业邮箱", mode: 1 },
            { title: "公众号留言", mode: 1 },
          ],
        },
      ],
    };
  },
  methods: {
    jump(path, mode) {
      let url = this.$route.path && this.$route.path.replace("/", "");
      console.log("jump---->>", path, mode);
      if (url !== path) {
        this.$router.push({ name: path, params: { mode } });
      } else {
        this.$emit("handleMode", mode);
      }
    },
    handleClick(path) {
      let url = this.$route.path && this.$route.path.replace("/", "");
      console.log("url", url);
      if (url !== path) {
        this.$router.push({ name: path });
      } else {
        this.$emit("handleMode", path == "home" ? "homeBrief" : "top");
      }
    },
  },
};
</script>

<style lang='less'scoped>
// 首页底部
.footer {
  width: 100%;
  //   height: 520px;
  padding: 60px 240px 58px;
  background: #081320;
  .company-name {
    font-size: 26px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
  }
  .line {
    display: block;
    height: 1px;
    background: #ffffff;
    opacity: 0.2;
    margin: 30px 0;
  }
  .detail {
    .left {
      width: 60%;
      display: flex;
      .item {
        width: 20%;
        .tag {
          display: inline-block;
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .tag-item {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          opacity: 0.5;
          margin-top: 10px;
        }
      }
    }
    .right {
      width: 40%;
      justify-content: flex-end;
      .phone-detail {
        .phone {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
        .nodes {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.7;
          margin-top: 10px;
        }
      }
      .image {
        width: 148px;
        height: 148px;
        background: #ffffff;
        margin-left: 50px;
      }
    }
  }
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    opacity: 0.7;
    .message-text {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .message-image {
      margin-left: 10px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>