<template>
  <div class="home" ref="home" @scroll="handleScroll">
    <tab-view></tab-view>
    <div class="home-top" ref="homeTop">
      <!-- <div class="top-title flex-level">
        Assist small and micro<br />
        enterprises and serve society
      </div>
      <div class="top-message flex-level">
        助力小微&nbsp;&nbsp;&nbsp;&nbsp; 服务社会
      </div>
      <div class="top-button flex-center cursor-pointer" @click="goIntro">
        查看更多
      </div> -->
      <img
        @click="handleMove"
        class="top-mouse cursor-pointer"
        src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1028.png"
      />
    </div>
    <!-- 集团简介 -->
    <div class="home-brief" ref="homeBrief">
      <div class="brief-name flex-bottom">
        <span class="name-zh">集团简介</span>
        <span class="name-eh">Group Profile</span>
      </div>
      <span class="brief-name-supplement"
        >为中小微企业解决融资难和信息不对称等问题</span
      >
      <i class="decorative-line"></i>
      <div class="brief-bottom flex-level">
        <div class="left brief-details">
          上海小就是大企业发展集团有限公司(以下简称“公司”)是一家以国学经典文化命名的公司，积极响应国家号召，紧跟国家宏观政策，响应金融服务实体和重点扶持中小微企业，助力实体经济发展，以客户服务为导向，着力深耕普惠金融市场，做企业主触手可及的金融服务者。<br /><br/>
          公司紧跟国家宏观政策，响应金融服务实体和重点扶持小微企业，秉承以客户为中心，以提升客户体验度为宗旨，深耕中国普惠金融市场。公司合作银行和机构达300多家，熟悉30多家机构的贷款政策和产品，482家商协会，380家俱乐部，还为企业提供30多种“小就是大甄选”服务，努力帮助中小微企业取得普惠的银行资金的同时，帮助企业对接资源，不良资产处置，核心团队从事金融行业7年以上，从2017至今服务了8000+上海本土企业，15000+个人企业家。<br /><br/>
          我们做好资金端和客户端的高效衔接平台，为中小微企业解决融资难和信息不对称等问题，让企业在融资这条路上可持续发展做大做强。助力小微，服务社会。
        </div>
        <div class="right">
          <img class="item" :key="url" :src="url" v-for="url in homeproList" />
        </div>
      </div>
      <div class="partner-info" ref="numberbox">
        <div
          class="item flex-vertical"
          v-for="item in inpartnerData"
          :key="item.type"
        >
          <div class="top flex-bottom">
            <span class="partner-number" :data-target="item.number"></span>
            <span class="partner-name">{{ item.unit }}</span>
          </div>
          <span class="bottom">{{ item.type }}</span>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="home-development-history" ref='homeHistory'>
      <img
        class="devlop-back"
        src="https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1002.png"
        alt=""
      />
      <div class="title-zh">发展历程</div>
      <div class="title-eh">Development history</div>
      <div class="development-history-time">
        <div
          @mouseenter="handleMouseenter(index)"
          class="item flex-vertical cursor-pointer"
          :class="{ active: index === activeTimeIndex }"
          v-for="(obj, index) in developTime"
          :key="index"
        >
          <span class="dot flex-vertical">
            <i class="border"></i>
          </span>
          <span class="time">{{ obj.time }}</span>
        </div>
      </div>
      <div class="development-history-bottom">
        <img
          class="image"
          :src="developTime[activeTimeIndex].url"
        />
        <div class="development-history-detal">
          <span class="company-name">上海小就是大企业发展集团有限公司</span>
          <i class="decorative-line"></i>
          <ul class="ul-box">
            <li>
              <i class="round-dot"></i
              >{{ developTime[activeTimeIndex].content }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 业务板块 -->
    <div class="home-business-sector" ref='homeSector'>
      <div class="home-business-sector-content">
        <div class="top">
          <div class="left">
            <div class="title-zh">业务板块</div>
            <div class="title-eh">Business sector</div>
            <div class="number">0{{ sectorActive + 1 }}.</div>
            <span class="sector-name" v-html="currentSector.title"></span>
            <span class="sector-content">{{ currentSector.text }}</span>
          </div>
          <div class="right">
            <el-carousel
              ref="carousel"
              @setActiveItem="setActiveItem"
              indicator-position="none"
              arrow="never"
              :autoplay="false"
            >
              <el-carousel-item
                v-for="item in businessSector"
                :key="item.title"
              >
                <img class="image" :src="item.url" alt="" />
                <!-- <div @click="goIntro" class="button flex-center cursor-pointer">
                  查看更多
                </div> -->
              </el-carousel-item>
            </el-carousel>
            <div
              @click="goIntro"
              class="more-right-button flex-center cursor-pointer"
            >
              查看更多
            </div>
          </div>
        </div>
        <div class="bottom">
          <div
            @click="setActiveItem(index)"
            class="item flex-vertical cursor-pointer"
            :class="{ 'sector-active': sectorActive == index }"
            v-for="(item, index) in businessSector"
            :key="item.title"
            v-html="item.title"
          ></div>
        </div>
      </div>
    </div>
    <!-- 团队风采 -->
    <div class="home-team-style" ref='homeStyle'>
      <div class="team-stitle">
        <div class="title-zh">团队风采</div>
        <div class="title-eh">Team Style</div>
      </div>
      <div class="style-top">
        <span class="style-detail">
          上海小就是大企业发展集团有限公司汇聚了一批人品正、专业度高、服务意识强的</br>
          人才队伍，人员规模达83人，团队人员在金融行业从业时间平均3年以上。</span>
      
        <div class="right">团队照片</div>
      </div>
      <div class="iamge-box">
        <img v-for="url in imgList" :key="url" class="image" :src="url" />
      </div>
    </div>
    <!-- 发展愿景 -->
    <div class="home-development-vision" ref='homeVision'>
      <div class="development-vision-box">
        <div class="title-zh">发展愿景</div>
        <div class="title-eh">Development Vision</div>
        <div class="development-vision-style">
          <div
            class="left item-box flex-vertical cursor-pointer"
            @mouseenter="devVisionStyle = devVisionData[0].style"
            @mouseleave="devVisionStyle = ''"
          >
            <img
              class="style-img"
              :src="
                devVisionStyle === devVisionData[0].style
                  ? devVisionData[0].activeUrl
                  : devVisionData[0].url
              "
            />
            <span class="style">{{ devVisionData[0].style }}</span>
            <span class="msg">{{ devVisionData[0].msg1 }}</span>
            <span class="msg">{{ devVisionData[0].msg2 }}</span>
          </div>
          <div class="right">
            <div
              @mouseenter="devVisionStyle = item.style"
              @mouseleave="devVisionStyle = ''"
              class="item item-box flex-vertical cursor-pointer"
              v-for="item in devVisionData.slice(1)"
              :key="item.style"
            >
              <img
                class="style-img"
                :src="devVisionStyle === item.style ? item.activeUrl : item.url"
              />
              <span class="style">{{ item.style }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer-box @handleMode='handlehumpScroll'></footer-box>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
import footerBox from "./../components/footer.vue";
export default {
  components: { tabView, footerBox },
  data() {
    return {
      homeproList: [
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1011.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1012.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1013.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1014.png",
      ],
      inpartnerData: [
        { type: "合作银行和机构", number: "300", unit: "+" },
        { type: "商协会", number: "482", unit: "家" },
        { type: "俱乐部", number: "380", unit: "家" },
        { type: "提供“小就是大甄选”服务", number: "30", unit: "+" },
      ],
      developTime: [
        {
          time: "2017",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2017.png",
          content: "人员规模6人 ，合作银行30多家 ，累计服务企业客户200家",
        },
        {
          time: "2018",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2018.png",
          content: "人员规模12人，合作银行50多家，累计服务企业客户600家",
        },
        {
          time: "2019",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2019.png",
          content: "人员规模18人，合作银行80多家，累计服务企业客户1500家",
        },
        {
          time: "2020",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2020.png",
          content: "人员规模32人，合作银行150多家，累计服务企业客户3000家",
        },
        {
          time: "2021",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2021.png",
          content: "人员规模50人，合作银行200多家，累计服务企业客户5000家",
        },
        {
          time: "2022",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2022.png",
          content: "人员规模71人，合作银行300多家，累计服务企业客户7000家",
        },
        {
          time: "2023",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/history/2023.png",
          content:
            "人员规模90人，合作300多家银行，30多家机构，服务过企业8000多家。2月正式启动事业合伙人模式，累计加入的7000多个合伙人，累计举办事业合伙人培训140多场，企业家沙龙160多场",
        },
      ],
      activeTimeIndex: 0,

      businessSector: [
        {
          title: "一起益<i></i>企新媒体",
          text: "录制企业家故事，传播企业家精神，弘扬正能量，专注企业家私域流量推广，一起说好中小企业自己的故事",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1021.png",
        },
        {
          title: "小就是<i></i>大商学院",
          text: "三人行必有我师，拜客户和同事为师，组织专场讲座分享，把专业的分享给自己人，虽然免费但内容很贵",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1022.png",
        },
        {
          title: "小就是<i></i>大名酒仓",
          text: "帮助中小企业解决用酒贵，用酒真，用酒快的问题，建立咱们自家的酒仓，自己人喝自己的酒",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1023.png",
        },
        {
          title: "小火<i></i>俱乐部",
          text: "打造中小企业主资源和信息平台，帮助俱乐部成员快速链接资源高效匹配，找到对的人一起做点开源的事",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1024.png",
        },
      ],
      sectorActive: 0,
      imgList: [
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1017.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1018.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1019.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1020.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1040.png",
        "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1041.png",
      ],
      devVisionStyle: "",
      devVisionData: [
        {
          style: "企业发展愿景",
          msg1: "让天下没有难借的钱，没有难做的生意！",
          msg2: "让人人敢创业，人人好创业！",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1027.png",
          activeUrl:
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1026.png",
        },
        {
          style: "共商",
          icon: "el-icon-burger",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1004.png",
          activeUrl:
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1003.png",
        },
        {
          style: "共建",
          icon: "el-icon-burger",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1008.png",
          activeUrl:
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1007.png",
        },
        {
          style: "共享",
          icon: "el-icon-burger",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1010.png",
          activeUrl:
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1009.png",
        },
        {
          style: "共富",
          icon: "el-icon-burger",
          url: "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1006.png",
          activeUrl:
            "https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1005.png",
        },
      ],
      changeNumber: false,
    };
  },
  computed: {
    currentSector() {
      return this.businessSector[this.sectorActive];
    },
  },
  cterate() {
    console.log("shouye--create");
  },
  activated() {
    console.log("shouye--activated");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(
        "from.name",
        from.name,
        vm.$refs.home,
        window.localStorage.getItem("homeScrollTop")
      );
      if (from.name == "homeBusDetail") {
        vm.$nextTick(() => {
          vm.$set(
            vm.$refs.home,
            "scrollTop",
            window.localStorage.getItem("homeScrollTop") || 0
          );
        });
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.handlehumpScroll();
      setTimeout(() => {
        let numberbox = this.$refs.numberbox;
        let numberHeight = numberbox.offsetHeight;
        let homeHeight = this.$refs.home.offsetHeight;
        let homeBrief = this.$refs.homeBrief.offsetHeight;
        let homeTop = this.$refs.homeTop.offsetHeight;
        let diff = Number(homeTop + homeBrief);
        if (diff <= homeHeight) {
          this.changeNumber = true;
          console.log("9999999999999999999999999999999999999");
          let numberList = document.getElementsByClassName("partner-number");
          console.log("numberList", numberList);
          for (let i = 0; i < numberList.length || 0; i++) {
            let ele = numberList[i];
            ele.innerText = 0;
            this.upDateNumber(ele, i);
          }
        }
      }, 500);
    });
  },
  methods: {
    handlehumpScroll(vMode) {
      let mode = vMode || (this.$route.params && this.$route.params.mode);
      console.warn("mode---->>>>", this.$route.params, mode);
      if (mode) {
        this.$nextTick(() => {
          let home = this.$refs.home;
          let homeTopHeight = this.$refs.homeTop.offsetHeight;
          let homeBriefHeight = this.$refs.homeBrief.offsetHeight;
          let homeHistoryHeight = this.$refs.homeHistory.offsetHeight;
          let homeSectorHeight = this.$refs.homeSector.offsetHeight;
          let homeStyleHeight = this.$refs.homeStyle.offsetHeight;
          console.log(
            homeTopHeight,
            homeBriefHeight,
            homeHistoryHeight,
            homeSectorHeight,
            homeStyleHeight
          );
          // let homeVisionHeight = this.$refs.homeVision.offsetHeight
          if (mode == "homeBrief") {
            home.scrollTop = 0;
          }
          if (mode == "homeHistory") {
            home.scrollTop = homeTopHeight + homeBriefHeight - 10;
          }
          if (mode == "homeSector") {
            home.scrollTop =
              homeTopHeight + homeBriefHeight + homeHistoryHeight - 10;
          }
          if (mode == "homeStyle") {
            home.scrollTop =
              homeTopHeight +
              homeBriefHeight +
              homeHistoryHeight +
              homeSectorHeight -
              10;
          }
          if (mode == "homeVision") {
            home.scrollTop =
              homeTopHeight +
              homeBriefHeight +
              homeHistoryHeight +
              homeSectorHeight +
              homeStyleHeight -
              10;
          }
        });
      }
    },
    handleMouseenter(index) {
      this.activeTimeIndex = index;
    },
    handleScroll() {
      window.localStorage.setItem("homeScrollTop", this.$refs.home.scrollTop);
      if (this.changeNumber) return;
      this.$nextTick(() => {
        let home = this.$refs.home;
        let numberbox = this.$refs.numberbox;
        let scrollTop = home.scrollTop;
        let numberHeight = numberbox.offsetHeight;
        let homeHeight = home.offsetHeight;
        let homeBrief = this.$refs.homeBrief.offsetHeight;
        let homeTop = this.$refs.homeTop.offsetHeight;
        if (scrollTop + homeHeight >= homeTop + homeBrief + numberHeight / 2) {
          this.changeNumber = true;
          let numberList = document.getElementsByClassName("partner-number");
          console.log("numberList", numberList);
          for (let i = 0; i < numberList.length || 0; i++) {
            let ele = numberList[i];
            ele.innerText = 0;
            this.upDateNumber(ele, i);
          }
        }
      });
    },
    upDateNumber(ele, i) {
      // 获取每个类名为number的data-target,即获取最大值
      const target = Number(ele.getAttribute("data-target"));
      // 获取当前div的数值
      const d = Number(ele.innerText);
      // 设置数据增加的值，可以通过target除的数值确定怎么加数值的快慢
      const incremnent = target / 50;
      // 当数字小于最大值时，执行下面的操作
      if (d < target) {
        // 向上取整
        ele.innerText = `${Math.ceil(d + incremnent)}`;
        // 1ms重新调用，不然它会在第一次运行完就结束
        this["timeId" + i] = setTimeout(() => {
          this.upDateNumber(ele, i);
        }, 10);
      } else {
        this["timeId" + i] && clearTimeout(this["timeId" + i]);
        ele.innerText = target;
      }
    },
    goIntro() {
      this.$router.push({
        name: "homeBusDetail",
        params: {
          sectorActive: this.sectorActive,
        },
      });
    },
    setActiveItem(index) {
      if (this.sectorActive === index) return;
      this.sectorActive = index;
      this.$refs.carousel.setActiveItem(index);
    },

    handleMove(e) {
      // console.log(e);
      let home = this.$refs.home;
      let scrollHeight = home.scrollHeight;
      let scrollTop = home.scrollTop;
      let height = home.offsetHeight;
      this.$nextTick(() => {
        if (scrollHeight > height + scrollTop) {
          console.log("------11", height + scrollTop);
          home.scrollTop = height + scrollTop - 20;
        } else {
          console.log("------222", scrollHeight - height);
          home.scrollTop = scrollHeight - height;
        }
        console.log("---", height, scrollHeight, scrollTop, home.scrollTop);
      });
    },
  },
};
</script>

<style lang='less' scoped>
.home {
  color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  // opacity: 0.9;
  overflow-x: hidden;
  overflow-y: auto;

  .title-zh {
    height: 48px;
    font-size: 50px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
  }
  .title-eh {
    display: block;
    height: 26px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 20px;
    margin-top: 10px;
  }
  .home-top {
    height: 1000px;
    background-image: url("https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1029.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 240px;
    padding-top: 400px;
    padding-bottom: 192px;
    position: relative;
    animation: change 5s;
    @keyframes change {
      0% {
        background-size: 100% 100%;
      }
      50% {
        background-size: 150% 150%;
      }
      100% {
        background-size: 100% 100%;
      }
    }
    .top-title {
      font-size: 72px;
      font-family: Humanst521 Lt BT;
      font-weight: 300;
      color: #ffffff;
      line-height: 80px;
      text-shadow: 0px 0px 6px rgba(27, 77, 146, 0.6);
    }
    .top-message {
      margin-top: 30px;
      height: 55px;
      font-size: 56px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      line-height: 20px;
      text-shadow: 0px 0px 6px rgba(27, 77, 146, 0.6);
    }
    .top-button {
      width: 272px;
      height: 80px;
      background: #a12f32;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      margin-top: 79px;
    }
    .top-mouse {
      width: 64px;
      height: 88px;
      background-color: #000;
      opacity: 0.3;
      border-radius: 32px;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(50%);
      font-size: 30px;
    }
  }
  // 集团简介
  .home-brief {
    // height: 783px;
    padding: 140px 240px;
    background-color: #f7f7f7;
    position: relative;
    .brief-name {
      .name-zh {
        display: block;
        height: 48px;
        font-size: 50px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1b4d92;
        line-height: 20px;
      }
      .name-eh {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #1b4d92;
      }
    }
    .brief-name-supplement {
      display: block;
      height: 18px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #444444;
      line-height: 30px;
      margin-top: 15px;
      // line-height: 30px;
    }
    .decorative-line {
      display: inline-block;
      border: 1px solid #1b4d92;
      padding: 0;
      content: "";
      margin-top: 15px;
      width: 34px;
    }
    .brief-bottom {
      margin-top: 63px;
      position: relative;
      min-height: 320px;
      .brief-details {
        width: 760px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #444444;
        display: flex;
        justify-content: space-between;
      }
      .right {
        width: calc(100% - 832px);
        height: 100%;
        margin-left: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        .item {
          width: calc(50% - 6px);
          height: calc(50% - 6px);
          background: #dcdbdb;
          margin-top: 6px;
          margin-left: 6px;
        }
      }
    }
    .partner-info {
      width: calc(100% - 480px);
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 11px 1px rgba(0, 18, 43, 0.08);
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 99;
      transform: translate(-50%, 50%);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 25%;
        border-right: 1px solid #e5e5e5;
        &:last-child {
          border-right: none;
        }
        .top {
          font-size: 64px;
          font-family: DIN Alternate;
          font-weight: bold;
          color: #a12f32;

          .partner-number {
            height: 46px;
            line-height: 46px;
          }
          .partner-name {
            height: 27px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 30px;
          }
        }
        .bottom {
          height: 17px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 30px;
          margin-top: 24px;
        }
      }
    }
  }
  // 发展历程
  .home-development-history {
    position: relative;
    padding: 180px 0px;
    padding-bottom: 120px;
    box-sizing: border-box;
    height: 896px;
    .devlop-back {
      position: absolute;
      width: 100%;
      z-index: -1;
      height: 1200px;
      top: 0;
    }
    .title-zh {
      padding: 0 240px;
    }
    .title-eh {
      padding: 0 240px;
    }
    .development-history-time {
      width: 100%;
      display: flex;
      justify-content: space-around;
      color: #444;
      position: absolute;
      top: 345px;
      border-top: 1px solid #aaa;
      box-sizing: border-box;
      padding: 0 240px;
      .item {
        margin-top: -12px;
        .dot {
          position: relative;
          display: block;
          width: 23px;
          height: 23px;
          padding: 10px;
          border-radius: 50%;
          border: 1px solid #aaaaaa;

          .border {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            border-radius: 50%;
            display: block;
            width: 13px;
            height: 13px;
            background-color: #aaaaaa;
          }
        }
        .time {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #444;
          line-height: 20px;
          margin-top: 25px;
        }
        &.active {
          .dot {
            border-color: #a12f32;
            .border {
              background-color: #a12f32;
            }
          }
          .time {
            color: #a12f32;
          }
        }
      }
    }
    .development-history-bottom {
      padding: 0 240px;
      margin-top: 180px;
      display: flex;
      .image {
        width: 440px;
        height: 320px;
        object-fit: cover;
        margin-right: 60px;
      }
      .development-history-detal {
        .company-name {
          height: 25px;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
          line-height: 30px;
        }
        .decorative-line {
          display: block;
          border: 1px solid #1b4d92;
          padding: 0;
          content: "";
          margin-top: 15px;
          width: 34px;
        }
        .ul-box {
          margin-top: 40px;
          list-style-type: none;
          li {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #111111;
            // line-height: 30px;
            display: flex;
            align-items: center;
            .round-dot {
              width: 12px;
              height: 12px;
              background: #1b4d92;
              border-radius: 50%;
              margin-right: 13px;
            }
          }
        }
      }
    }
  }
  // 业务板块
  .home-business-sector {
    width: 100%;
    .home-business-sector-content {
      width: 100%;
      padding: 120px 240px 0;
      background-color: #f7f7f7;
      border-radius: 240px 0px 0px 0px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      // overflow: hidden;
      .left {
        width: calc(30% - 00px);
        margin-right: 90px;
      }
      .right {
        width: 70%;
        min-height: 500px;
        height: 100%;
        border-radius: 0px 140px 0px 0px;
        overflow: hidden;
        position: relative;
        .more-right-button {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 272px;
          height: 80px;
          background: #a12f32;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          z-index: 999;
        }

        /deep/.el-carousel {
          min-height: 500px;
          height: 100%;
          .el-carousel__container,
          .el-carousel__item {
            min-height: 500px;
            height: 100%;
          }
          .image {
            width: 100%;
            height: 100%;
          }
        }
      }
      .number {
        // height: 73px;
        font-size: 100px;
        font-family: DIN Alternate;
        font-weight: bold;
        color: #1b4d92;
        margin-top: 60px;
      }
      .sector-name {
        display: block;
        // margin-top: 20px;
        margin-bottom: 40px;
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1b4d92;
      }
      .sector-content {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #444444;
        // line-height: 26px;
        letter-spacing: 1px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 100px;
      .item {
        width: 25%;
        height: 200px;
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1b4d92;
        line-height: 48px;

        &.sector-active {
          color: #a12f32;
          background: #ffffff;
          box-shadow: 0px 0px 11px 1px rgba(0, 18, 43, 0.08);
          border-radius: 32px 32px 0px 0px;
        }
      }
    }
  }
  // 团队风采
  .home-team-style {
    padding: 140px 240px;
    background-image: url("https://xjsd-web.oss-cn-shanghai.aliyuncs.com/index/1016.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .team-stitle {
      .title-zh,
      .title-eh {
        color: #a12f32;
      }
    }
    .style-top {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      margin-top: 40px;

      .style-detail {
        flex: 1;
        width: 591px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #444444;
        line-height: 26px;
      }
      .right {
        width: 200px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1b4d92;
        text-align: end;
        padding: 15px;
        border-bottom: 1px solid #1b4d92;
      }
    }
    .iamge-box {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .image {
        width: calc(33.33% - 28px);
        margin-right: 42px;
        height: 280px;
        margin-top: 30px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // 发展愿景
  .home-development-vision {
    background-color: #cfd0de;

    .development-vision-box {
      width: 100%;
      height: 100%;
      padding: 120px 240px 140px;
      background: #f7f7f7;
      border-radius: 0px 240px 0px 0px;
      opacity: 0.9;
    }
    .development-vision-style {
      display: flex;
      justify-content: space-between;
      height: 600px;
      margin-top: 50px;
      .style {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 20px;
      }
      .style-img {
        width: 90px;
        height: 90px;
      }

      .left {
        width: calc(50% - 10px);
        margin-right: 10px;
        background: #ffffff;
        .style {
          margin-top: 70px;
          margin-bottom: 110px;
        }
        .msg {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #111111;
          line-height: 60px;
        }
      }
      .right {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        .item {
          width: calc(50% - 5px);
          height: calc(50% - 5px);
          background: #ffffff;
          .style-img {
            width: 86px;
            height: 86px;
          }
          .style {
            margin-top: 20px;
          }
        }
      }
      .item-box:hover {
        background: #a12f32;
        .icon,
        .style,
        .msg {
          color: #ffffff;
        }
      }
    }
  }
}
</style>