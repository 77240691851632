<template>
  <el-dialog
    width="600px"
    :append-to-body="true"
    :title="title"
    :visible.sync="show"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    :close-on-click-modal="false"
  >
    <el-upload
      :headers="headers"
      action="http://api.xd98.cn/xd/oss/upload"
      :on-success="handleAvatarSuccess"
      v-loading="uploading"
      class="avatar-uploader flex-vertical"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="newsData.cover" :src="newsData.cover" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-date-picker
      class="time-change input-box"
      v-model="newsData.time"
      type="date"
      value-format="timestamp"
      placeholder="选择日期"
    >
    </el-date-picker>

    <el-select
      class="time-change input-box"
      v-model="newsData.type"
      placeholder="请选择类型"
    >
      <el-option
        v-for="item in tabList"
        :key="item.type"
        :label="item.zh"
        :value="item.type"
      >
      </el-option>
    </el-select>
    <el-input
      class="input-text input-box"
      placeholder="请输入文章链接"
      v-model="newsData.url"
      clearable
    >
    </el-input>
    <el-input
      show-word-limit
      class="input-textarea input-box"
      rows="2"
      type="textarea"
      placeholder="请输入标题"
      maxlength="100"
      v-model.trim="newsData.title"
    >
    </el-input>
    <div class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="saveData">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      headers: {
        Authorization: "Bearer " + this.token, //headers属性中添加token，这个属性是el-upload自带的用来设置上传请求头部
      },
      uploading: false,
      newsData: {
        id: "",
        type: "",
        title: "",
        cover: "",
        url: "",
        time: "",
        digest: "",
      },
    };
  },
  props: {
    token: {
      type: String,
    },
    isShow: {
      type: Boolean,
      defaule: false,
    },
    formData: {
      type: Object,
      defaule: {},
    },
    tabList: {
      type: Array,
      defaule: [],
    },
  },
  watch: {
    formData: {
      handler(obj) {
        this.initData(obj);
      },
      deep: true,
    },
  },
  model: {
    prop: "isShow",
    event: "change",
  },
  computed: {
    title() {
      return this.formData.id ? "编辑" : "新增";
    },
    show: {
      get() {
        return this.isShow;
      },
      set() {
        this.$emit("change", false);
      },
    },
  },
  created() {
    this.initData(this.formData);
  },

  methods: {
    IsURL(str_url) {
      var strRegex =
        "^((https|http|ftp|rtsp|mms)?://)" +
        "?(([0-9a-zA-Z_!~*'().&=+$%-]+: )?[0-9a-zA-Z_!~*'().&=+$%-]+@)?" + //ftp的user@
        "(([0-9]{1,3}.){3}[0-9]{1,3}" + // IP形式的URL- 199.194.52.184
        "|" + // 允许IP和DOMAIN（域名）
        "([0-9a-zA-Z_!~*'()-]+.)*" + // 域名- www.
        "([0-9a-zA-Z][0-9a-zA-Z-]{0,61})?[0-9a-zA-Z]." + // 二级域名
        "[a-zA-Z]{2,6})" + // first level domain- .com or .museum
        "(:[0-9]{1,4})?" + // 端口- :80
        "((/?)|" +
        "(/[0-9a-zA-Z_!~*'().;?:@&=+$,%#-]+)+/?)$";
      var re = new RegExp(strRegex);
      return re.test(str_url);
    },
    // 保存编辑
    async saveData() {
      if (this.uploading) {
        this.$message.warning("请稍等，正在上传图片");
        return;
      }
      if (
        !this.newsData.title ||
        !this.newsData.cover ||
        !this.newsData.url ||
        !this.newsData.time
      ) {
        this.$message.warning("请完善信息");
        return;
      }
      // let urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-z]{2,}(\/.*)?$/;
      if (!this.IsURL(this.newsData.url)) {
        this.$message.warning("您输入的不是有效链接");
        return;
      }
      // let type = this.tabList[this.currentTabIndex].type;
      const formData = new FormData();
      formData.append("type", this.newsData.type);
      formData.append("title", this.newsData.title);
      formData.append("cover", this.newsData.cover);
      formData.append("url", this.newsData.url);
      formData.append("time", this.newsData.time / 1000);
      formData.append("digest", this.newsData.digest);
      formData.append("id", this.newsData.id || "0");
      let res = await this.$axios.post("/xd/article/update", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("res---->>>>", res);
      if (res.data && res.data.code === 0) {
        this.$message.success(this.newsData.id ? "修改成功" : "添加成功");
        this.$emit("handleList");
        this.handleCancel();
      } else {
        this.$message.error(res.data.message);
      }
    },
    initData(obj) {
      let initObj = {
        id: "",
        type: "",
        title: "",
        cover: "",
        url: "",
        time: new Date().getTime(),
        digest: "",
      };
      this.newsData = Object.assign({}, initObj, obj);
      console.log("this.newsData--", this.newsData);
    },
    handleAvatarSuccess(res, file) {
      console.log("this.imageUrl---", res, file, this.imageUrl);
      if (res.code === 0 && res.data) {
        this.newsData.cover = res.data.url;
      } else {
        this.$message.error(res.message || "上传失败");
      }
      this.uploading = false;
    },
    async beforeAvatarUpload(file) {
      this.uploading = true;
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;

      return true;
    },
    handleCancel() {
      this.beforeClose();
    },
    beforeClose() {
      this.newsData = {
        id: "",
        type: "",
        title: "",
        cover: "",
        url: "",
        time: "",
        digest: "",
      };
      this.$emit("change", false);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-dialog {
  .el-dialog__title {
    font-size: 30px;
  }
  .el-dialog__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
  .el-input__inner {
    width: 100% !important;
    height: 100% !important;
  }
  .input-box {
    margin-bottom: 15px;
  }
  .el-input {
    width: 100%;
    height: 100%;
  }
  .avatar-uploader {
    width: 350px;
    height: 300px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    .el-upload {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .el-upload,
    .avatar {
      width: 100%;
      height: 100%;
    }
  }
  .time-change {
    width: 500px;
    height: 80px;
  }
  .input-text {
    height: 80px;
  }
  @media screen and (min-width: 1000px) {
    .el-dialog__title {
      font-size: 20px;
    }
    .time-change {
      height: 60px;
    }
    .input-text {
      height: 60px;
    }
    .avatar-uploader {
      width: 280px;
      height: 240px;
    }
  }
}
</style>