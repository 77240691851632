<template>
  <div class="NewsDetail">
    <tab-view></tab-view>
    <div class="detail-top">
      <div class="title-content">
        <div class="title">
          新闻标题，展示标题的全部内容，帮助企业预防改善构建健康的信用体系，帮助
          企业预防改善构建健康的信用体系
        </div>
        <div class="time">2023-12-09</div>
      </div>
      <i class="line"></i>
    </div>
    <footer-box></footer-box>

    <!-- <div class="new-content" v-html="url"></div> -->
    <iframe
      src="https://mp.weixin.qq.com/s?__biz=MzkzMTI1MjQ0OQ==&mid=2247493164&idx=1&sn=ae76f9b69add64e3ad6b43449fb79675&chksm=c26f77a2f518feb4a7ddc16468bd55f6599c9b44ebec197b6e40dd5f568b72807f7260e9b943#rd"
      frameborder="0"
      scrolling="no"
    ></iframe>
    <iframe
      style="width: 100%; height: 500px"
      src="https://element.eleme.cn/#/zh-CN/component/installation"
    ></iframe>
  </div>
</template>

<script>
import tabView from "./../components/tabView.vue";
import footerBox from "./../components/footer.vue";
import url from "./../components/test";
export default {
  components: { tabView, footerBox },
  data() {
    return {
      id: "",
      url: url,
    };
  },
  created() {
    this.id = this.$route.params.id || "首页";
    console.log("this.currentTab---->", this.id);
    // console.log("url---------->>", url);
  },
};
</script>

<style lang='less' scoped>
.NewsDetail {
  .detail-top {
    height: 1000px;
    width: 100%;
    position: relative;
    background-image: url("./../assets/image/1.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .title-content {
      position: absolute;
      bottom: 62px;
      //   height: 400px;
      padding: 110px 330px 0;
      background: #ffffff;
      border-radius: 240px 0px 0px 0px;

      .title {
        text-align: center;
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #001111;
        margin-bottom: 40px;
      }
      .time {
        text-align: center;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #888888;
        line-height: 20px;
        margin-bottom: 50px;
      }
    }
    .line {
      width: 100%;
      background: #ffffff;
      position: absolute;
      bottom: 0;
      display: block;
      height: 62px;
      border-top: 1px solid #d6d9dd;
    }
  }
}
</style>