// 引入vue
import Vue from 'vue'
// 引入主组件
import App from './App.vue'
// rem配置.引入amfe-flexible
// import 'amfe-flexible'
import './utils/flexible.js'

import './assets/style/base.less'
// 引入路由
import router from './router'
import store from './store'
Vue.use(store)
Vue.use(require('vue-wechat-title'))

// 引入axios(axios是一个基于promise的http库，发送ajax请求，可以在浏览器和node.js中)
import axios from 'axios'
// 添加基地址
axios.defaults.baseURL = 'http://api.xd98.cn'
// 将axios添加到Vue原型上,因为组件就是Vue实例,可以使用Vue原型上的属性和方法
Vue.prototype.$axios = axios   //(以后组件里面使用 this.$axios)

// ========================拦截器================
// 每次请求都需要发送token麻烦
// 添加请求拦截器
axios.interceptors.request.use((config) => {
  // 在发送请求之前做些什么
  let token = store.state.userInfo.token
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  console.warn('config-->', config)
  return config;
})


// 引入moment
import moment from 'moment'
// ==================过滤器================
Vue.filter('date', (res, formate = 'YYYY年MM月DD日') => {
  return moment(res).format(formate)
})

// =====================事件总线=================
const bus = new Vue()
Vue.prototype.$bus = bus

// 引入vant 轻量可靠的移动端vue组件库




// 引入element-ui(完整引入)
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 实例vue
new Vue({
  store,
  router,
  // 渲染主组件
  render: (h) => h(App)
}).$mount('#app')  //限制边界(index.html)
